export const navItems = [
    {
        name: "Home",
        to: "/#home",
    },
    {
        name: "Project",
        to: "/#projects",
    },
    {
        name: "About",
        to: "/#about",
    },
    {
        name: "Technologies",
        to: "/#technologies",
    },
    {
        name: "Contact",
        to: "/#contact",
    },
];
